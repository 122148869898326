import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import RaydaRemoteSidebar from "./RaydaRemoteSidebar";
import Header from "./Header";
import Footer from "./Footer";

export default function RaydaRemote() {

  const offsets = ["/apps/file-manager", "/apps/email", "/apps/calendar"];
  const { pathname } = useLocation();
  const bc = document.body.classList;

  // set sidebar to offset
  (offsets.includes(pathname)) ? bc.add("sidebar-offset") : bc.remove("sidebar-offset");

  // auto close sidebar when switching pages in mobile
  bc.remove("sidebar-show");

  // scroll to top when switching pages
  window.scrollTo(0, 0);

  return (
    <React.Fragment>
      <RaydaRemoteSidebar />
      <React.Fragment>
        <Header />
        <React.Fragment>
          <Outlet />
        </React.Fragment>
        <Footer />
      </React.Fragment>
      
    </React.Fragment>
  )
}