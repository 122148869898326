import remoteApi from "../rayda-remote.interceptor";

export const requestDashboardData = async () => {
  return await remoteApi.get(`/api/v1/admin/dashboard`);
};

export const requestAllCompanies = async () => {
  return await remoteApi.get(`/api/v1/admin/companies`);
};

export const requestSingleCompany = async (company_id) => {
  return await remoteApi.get(`/api/v1/admin/companies/${company_id}`);
};

export const requestAllEmployee = async (company_id) => {
  return await remoteApi.get(`/api/v1/admin/companies/${company_id}/employees`);
};

export const requestCompanyOrder = async (company_id) => {
  return await remoteApi.get(`/api/v1/admin/companies/${company_id}/orders`);
};

export const requestCompanyUsers = async (company_id) => {
  return await remoteApi.get(`/api/v1/admin/companies/${company_id}/users`);
};

export const requestCompanyAssets = async (company_id) => {
  return await remoteApi.get(`/api/v1/admin/companies/${company_id}/assets`);
};

export const requestSingleEmployee = async (employee_id) => {
  return await remoteApi.get(`/api/v1/admin/employee/${employee_id}/details`);
};

export const requestAllOrders = async () => {
  return await remoteApi.get(`/api/v1/admin/orders`);
};

export const requestSingleOrder = async (order_id) => {
  return await remoteApi.get(`/api/v1/admin/orders/${order_id}`);
};

export const requestUpdateOrderStatus = async (order_id, lastNode, formData) => {
  return await remoteApi.put(`/api/v1/admin/orders/${order_id}/${lastNode}`, formData);
};

export const requestAllClients = async () => {
  return await remoteApi.get(`/api/v1/admin/client`);
};

export const requestSingleClients = async (client_id) => {
  return await remoteApi.get(`/api/v1/admin/client/${client_id}`);
};

export const requestCreateEmployee = async (company_id, formData) => {
  return await remoteApi.post(`/api/v1/admin/companies/${company_id}/employees`, formData);
};

export const requestAllAsset = async () => {
  return await remoteApi.get(`/api/v1/admin/assets`);
};

export const requestCreateCompanyDetails = async (formData) => {
  return await remoteApi.post(`/api/v1/admin/companies/`, formData);
};

export const requestShippingOptions = async () => {
  return await remoteApi.get(`/api/v1/admin/options`);
};

export const requestAddOrder = async (formData) => {
  return await remoteApi.post(`/api/v1/admin/orders`, formData);
};

export const requestCancelOrder = async (order_id) => {
  return await remoteApi.put(`/api/v1/admin/orders/${order_id}/cancel`);
};