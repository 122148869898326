import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const CustomTelInput = ({setValue, initialData,name, value, label, error, getValue, handleBlur, children, required}) => {
    const styleInput = { 
        border: '1px solid #d0d5dd',
        borderRadius: "4px",
        width: "100%",
        fontSize: "16px",
        color: "#555",
        outline:" none",
        height: "48px",
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)"
    }

    const formLabel = {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#344054",
        margin: "26px 0 12px 0",
        display: "block",
    }
  return (
    <div style={{width: "100%"}}>
        <label htmlFor={name} style={formLabel}>{label}</label>
        <PhoneInput
            country={"ng"}
            inputProps={{
            name: {name},
            country: "ng",
            required: true,
            }}
            value={value}
            onChange={(phone) => {
                if(getValue){
                    getValue(phone)
                }

                if(setValue) {
                    setValue({...initialData, [name] : phone})
                }
            }}
            inputStyle={styleInput}
            onBlur={handleBlur}
        />
        {
            error && <div className="alert alert-danger">{error}</div>
        }
        {
            children
        }
    </div>
    
  )
}

export default CustomTelInput