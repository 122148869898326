import authApi from '../authInterceptor';

export const requestCreatAdmin = async (formData) => {
  return await authApi.post(`api/v1/users`, formData);
};

export const requestEditAdmin = async (admin_id,formData) => {
  return await authApi.put(`api/v1/users/${admin_id}`, formData);
};

export const requestLogin = async (formData) => {
  return await authApi.post(`api/v1/login`, formData);
};

export const requestLogout = async () => {
  return await authApi.post(`api/v1/logout`);
};

export const requestSetPassword = async (formData) => {
  return await authApi.post(`api/v1/password`, formData);
};

export const requestAllAdmins = async () => {
  return await authApi.get(`api/v1/users`);
};

export const requestSingleAdmin = async (admin_id) => {
  return await authApi.get(`api/v1/users/${admin_id}`);
};