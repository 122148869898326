import React, { Fragment } from 'react';
import '../../assets/css/components/shared/buttonStyles.css'

const Button = ({
  bg_default,
  bg_blue,
  has_blackborder,
  handleClick,
  withBorderColor,
  withBorderTransparentColor,
  requestBtn,
  hasNoBorder,
  children,
  disabled,
  data_cy,
  type
}) => {
  return (
    <Fragment>
      {bg_default && (
        <button className='buttonstyle'
          data-testid="button"
          data-cy={data_cy}
          type={type || 'button'}
          disabled={disabled}
          onClick={handleClick}
        >
          {children}
        </button>
      )}

      {bg_blue && (
        <button className='blue_buttonStyle' type={type || 'button'} data-cy={data_cy} disabled={disabled} onClick={handleClick}>
          {children}
        </button>
      )}

      {has_blackborder && (
        <button className='hablackborderButtonStyle' type={type || 'button'} data-cy={data_cy} disabled={disabled} onClick={handleClick}>
          {children}
        </button>
      )}
      {withBorderColor && (
        <button className='withBorderColorStyles' type={type || 'button'} data-cy={data_cy} disabled={disabled} onClick={handleClick}>
          {children}
        </button>
      )}
      {withBorderTransparentColor && (
        <button className='withBorderTransparentColorStyles'
          type={type || 'button'}
          data-cy={data_cy}
          disabled={disabled}
          onClick={handleClick}
        >
          {children}
        </button>
      )}
      {hasNoBorder && (
        <button className='hasNoBorderStyles' type={type || 'button'} data-cy={data_cy} disabled={disabled} onClick={handleClick}>
          {children}
        </button>
      )}
      {requestBtn && (
        <button className='requestStyles' type={type || 'button'} data-cy={data_cy} disabled={disabled} onClick={handleClick}>
          {children}
        </button>
      )}
    </Fragment>
  );
};

export default Button;
