import { ADD_PRODUCT, DECREASE_PRODUCT, INCREASE_PRODUCT, REMOVE_ALL_PRODUCTS, REMOVE_PRODUCT } from "./types";

const initialState = [];

const productToCartReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case ADD_PRODUCT:
            return [
                ...state,
                {
                    ...payload,
                    qty: 1,
                }
            ];
        case INCREASE_PRODUCT:
            const asset_id = payload.asset_id;
            const quantity = payload.quantity;

            if (quantity === 0) {
                const items = state.filter(it => it.id !== asset_id);
                return { ...state, ...items };
            } else {
                const index = state.findIndex(it => it.id === asset_id);
                if (index !== -1) {
                    const newItems = [...state];
                    const item = { ...state[index], qty : quantity  + 1 };
                    newItems[index] = { ...item };

                    return [...newItems];
                } 
            }
            return state;
        case DECREASE_PRODUCT:
            const asset_id_DC = payload.asset_id;
            const quantity_DC = payload.quantity;

            if (quantity_DC === 1) {
                return state;
            } else {
                const index = state.findIndex(it => it.id === asset_id_DC);
                if (index !== -1) {
                    const newItems = [...state];
                    const item = { ...state[index], qty : quantity_DC  - 1 };
                    newItems[index] = { ...item };

                    return [...newItems];
                } 
            }
            return state;
        case REMOVE_PRODUCT:
            const items = state.filter(it => it.id !== payload.asset_id);
            return [...items];
            
        case REMOVE_ALL_PRODUCTS:
            return []
        default:
            return state;
    };
};

export default productToCartReducer;