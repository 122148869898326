import { ADD_PRODUCT, DECREASE_PRODUCT, INCREASE_PRODUCT, REMOVE_ALL_PRODUCTS, REMOVE_PRODUCT } from "../../reducers/products/types";

const add_ProductsAction = (payload) => (
    {
        type: ADD_PRODUCT,
        payload
    }
)
const increase_ProductsAction = (payload) => (
    {
        type: INCREASE_PRODUCT,
        payload
    }
)

const decrease_ProductsAction = (payload) => (
    {
        type: DECREASE_PRODUCT,
        payload
    }
)

const remove_ProductAction = (payload) => (
    {
        type: REMOVE_PRODUCT,
        payload
    }
)

const remove_All_ProductAction = () => (
    {
        type: REMOVE_ALL_PRODUCTS,
    }
)

export {add_ProductsAction, increase_ProductsAction, decrease_ProductsAction, remove_ProductAction, remove_All_ProductAction}