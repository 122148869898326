export const baseURL = (appType = "rayda-remote") => {
  if (appType === "rayda-remote") {
    return process.env.REACT_APP_ENV === "local"
      ? process.env.REACT_APP_REMOTE_LOCAL_URL
      : process.env.REACT_APP_ENV === "staging"
      ? process.env.REACT_APP_REMOTE_STAGING_URL
      : process.env.REACT_APP_ENV === "production"
      ? process.env.REACT_APP_REMOTE_PROD_URL
      : "";
  } else if (appType === "private-sale") {
    return process.env.REACT_APP_ENV === "local"
      ? process.env.REACT_APP_BIDDA_LOCAL_URL
      : process.env.REACT_APP_ENV === "staging"
      ? process.env.REACT_APP_BIDDA_STAGING_URL
      : process.env.REACT_APP_ENV === "production"
      ? process.env.REACT_APP_BIDDA_PROD_URL
      : "";
  }
};

export let authURL =
  process.env.REACT_APP_ENV === "local"
    ? process.env.REACT_APP_AUTH_LOCAL_URL
    : process.env.REACT_APP_ENV === "staging"
    ? process.env.REACT_APP_AUTH_STAGING_URL
    : process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_AUTH_PROD_URL
    : "";

export const setToken = (value) =>
  localStorage.setItem("remoteAdmin-token", value);
export const setAdminData = (value) =>
  localStorage.setItem("remoteAdmin-data", value);

export const getAdminData = () => localStorage.getItem("remoteAdmin-data");

export const getToken = () => localStorage.getItem("remoteAdmin-token");

export const deleteToken = () => localStorage.removeItem("remoteAdmin-token");

export const handleCurrencySymbolGeneartion = (iso, amount) => {
  if (iso === "NGN" || iso === "ngn")
    return "₦" + parseInt(amount)?.toLocaleString("en-us");

  const currInstance = new Intl.NumberFormat("en-uk", {
    style: "currency",
    currency: iso,
  });

  return currInstance
    .formatToParts(amount)
    .map((val) => val.value)
    .join("");
};
