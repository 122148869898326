import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "../features/api/auth/authApi";
import productToCartReducer from "../stateManagement/redux/reducers/products/product.reducers";

const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        productToCart: productToCartReducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat()
});

export default store;