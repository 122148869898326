import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import * as EmailValidator from 'email-validator';

import '../../assets/css/page/createEmployeeStyles.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { country_state, handpickedCountryState } from '../../data/countries';
import CustomInput from '../../components/shared/CustomInput';
import CustomSelect from '../../components/shared/CustomSelect';
import CustomSpinner from '../../components/shared/CustomSpinner';
import CustomTelInput from '../../components/shared/CustomTelInput';
import MapLocationFinder from '../../components/shared/MapLocationFinder';
import { createEmployeeAction } from '../../services/businessLogic/raydaRemote.actions';
import Button from '../../components/shared/Button';
import { Row } from 'react-bootstrap';

const CreateEmployee = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryClient = useQueryClient();

  const company_id = location?.state?.company_id;
  const state_companyName = location?.state?.comapny_name;

  const [nextpage, setNexpage] = useState(false);

  const [state_province, setState_province] = useState([]);

  const [isDisAbled, setIsDisAbled] = useState(true);
  const [selectedCounryCode, setSelectedCountrycode] = useState({});


  useEffect(() => {
    setNexpage(false);
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      role: '',
      employee_type: ''
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required('First name is required'),
      last_name: Yup.string().required('Las name is required'),
      email: Yup.string().email().required('Email address is required'),
      role: Yup.string().required('Role is required'),
      employee_type: Yup.string().required('Employee type is required')
    }),

    onSubmit: async (values) => {
      setNexpage(true);
      setIsDisAbled(true);
    }
  });

  const { mutateAsync: handleCreateEmployee, isLoading: handleCE_loader } = useMutation((variables) => createEmployeeAction(variables), {
    onSuccess: (response) => {
      const {
        data: { success, data }
        
      } = response;
      
      if (success) {
        toast.success('Employee Created Successfully');
        queryClient.invalidateQueries('all_employees');

        navigate('/rayda-remote/orders/add-orders', { state: { firstName:  data.first_name, lastName: data.last_name, employee_id: data.id, company_id:  company_id, comapny_name: state_companyName }});

      }
    },

    onError: (error) => {
      toast.error(`Something went wrong: ${error.message}`);
    }
  });

  

  const shipping_formik = useFormik({
    initialValues: {
      telephone: '',
      country: '',
      state: '',
      address: '',
      city: ''
    },
    validationSchema: Yup.object().shape({
      telephone: Yup.string().required('Phone number is required'),
      address: Yup.string().required('Address is required'),
      country: Yup.string().required('Country Of residence is required'),
      state: Yup.string().required('State / Province is required'),
      city: Yup.string().required('City is required')
    }),

    onSubmit: async (values) => {
      setNexpage(true);

      const formData = {
        first_name: formik.values.first_name,
        last_name: formik.values.last_name,
        role: formik.values.role,
        employee_type: formik.values.employee_type,
        email: formik.values.email,
        telephone: values.telephone,
        address: values.address,
        city: values.city,
        state: values.state,
        country: values.country
      };

      await handleCreateEmployee({ company_id, formData });
    }
  });

  useEffect(() => {
    if (
      formik.values.email !== '' &&
      EmailValidator.validate(formik.values.email) &&
      formik.values.first_name !== '' &&
      formik.values.last_name !== '' &&
      formik.values.employee_type !== '' &&
      formik.values.role
    ) {
      setIsDisAbled(false);
    } else {
      setIsDisAbled(true);
    }
  }, [
    formik.values.email,
    formik.values.first_name,
    formik.values.last_name,
    formik.values.employee_type,
    formik.values.role
  ]);

  useEffect(() => {
    if (
      shipping_formik.values.telephone !== '' &&
      shipping_formik.values.country !== '' &&
      shipping_formik.values.state !== '' &&
      shipping_formik.values.address !== '' &&
      shipping_formik.values.city !== ''
    ) {
      setIsDisAbled(false);
    } else {
      setIsDisAbled(true);
    }
  }, [
    shipping_formik.values.telephone,
    shipping_formik.values.country,
    shipping_formik.values.state,
    shipping_formik.values.address,
    shipping_formik.values.city
  ]);

  useEffect(() => {
    const findeData = country_state.filter((item) => item.country === shipping_formik.values.country);
    setState_province(findeData[0]?.states);
  }, [shipping_formik.values.country]);

  const getOfficeLocation = (location) => {

    if (location.address) {
      shipping_formik.setFieldValue('address', location?.address);
    }
  };

  return (

    <div className="main main-app p-3 p-lg-4">
            <div className="d-flex align-items-center justify-content-between mb-4">
                <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item active" aria-current="page">Create Employee</li>
                </ol>
            </div>

            <Row>
              <div className='ce_container'>
        
                <h3 className="ce_title">{!nextpage ? 'Employee info' : 'Shipping Details'}</h3>
                <p className="ce_subtitle">
                  {!nextpage
                    ? `Please fill in your employee information here.`
                    : `Please add your employees shipping details here.`}
                </p>

                <form onSubmit={!nextpage ? formik.handleSubmit : shipping_formik.handleSubmit}>
                  {!nextpage ? (
                    <div className="form_container">
                      <div className="form_field_container">
                        <section className="aside_left">
                          <CustomInput
                            label={'First Name'}
                            name="first_name"
                            type="text"
                            placeholder={'Enter first name'}
                            value={formik.values.first_name}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                          >
                            {formik.touched.first_name && formik.errors.first_name ? (
                              <div style={{ color: 'red', fontSize: '12px' }}>{formik.errors.first_name}</div>
                            ) : null}
                          </CustomInput>
                        </section>
                        <section className="aside_right">
                          <CustomInput
                            label={'Last Name'}
                            name="last_name"
                            type="text"
                            placeholder={'Enter last name'}
                            value={formik.values.last_name}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                          >
                            {formik.touched.last_name && formik.errors.last_name ? (
                              <div style={{ color: 'red', fontSize: '12px' }}>{formik.errors.last_name}</div>
                            ) : null}
                          </CustomInput>
                        </section>
                      </div>
                      <CustomInput
                        label={'Email Address'}
                        name="email"
                        type="email"
                        placeholder={'Enter email address'}
                        value={formik.values.email}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                      >
                        {formik.touched.email && formik.errors.email ? (
                          <div style={{ color: 'red', fontSize: '12px' }}>{formik.errors.email}</div>
                        ) : null}
                      </CustomInput>
                      <CustomInput
                        label={'Role'}
                        name="role"
                        type="text"
                        placeholder={'Enter role'}
                        value={formik.values.role}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                      >
                        {formik.touched.role && formik.errors.role ? (
                          <div style={{ color: 'red', fontSize: '12px' }}>{formik.errors.role}</div>
                        ) : null}
                      </CustomInput>
                      <CustomSelect
                        label={'Employee Type'}
                        optionData={['Contract', 'Full time', 'Freelance', 'Other']}
                        isDefault
                        placeholder={'Select employee type'}
                        handleChange={async ({ value }) => formik.setFieldValue('employee_type', value)}
                        handleBlur={formik.handleBlur}
                      />
                      <div className="meta" />
                      {!handleCE_loader ? (
                        <div className="ce_btn_container">

                          <div className="btn_subC">
                            <Button bg_blue={true} type={'submit'} disabled={isDisAbled}>
                              Continue
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div style={{ margin: 'auto' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <CustomSpinner />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="form_container">
                      <CustomTelInput
                        label={'Phone number'}
                        name="telephone"
                        value={shipping_formik.values.telephone}
                        handleBlur={shipping_formik.handleBlur}
                        getValue={(value) => shipping_formik.setFieldValue('telephone', value)}
                      >
                        {shipping_formik.touched.telephone && shipping_formik.errors.telephone ? (
                          <div style={{ color: 'red', fontSize: '12px' }}>{shipping_formik.errors.telephone}</div>
                        ) : null}
                      </CustomTelInput>
                      <CustomSelect
                        label={'Country Of Residence'}
                        isDefault
                        optionData={handpickedCountryState}
                        select_by={!handpickedCountryState?.length < 1 ? 'country' : ''}
                        defaultStructure
                        placeholder={'Select country of residence'}
                        handleChange={async ({ value }) => {

                          setSelectedCountrycode(handpickedCountryState?.find(({ country }) => country === value)?.alpha2Code);

                          shipping_formik.setFieldValue('country', value)
                        }}
                        handleBlur={shipping_formik.handleBlur}
                      />
                      <CustomSelect
                        label={'State / Province'}
                        optionData={state_province}
                        isDefault
                        placeholder={'Select state / province'}
                        handleChange={async ({ value }) => shipping_formik.setFieldValue('state', value)}
                        handleBlur={shipping_formik.handleBlur}
                      />
                      <CustomInput
                        label={'City'}
                        name="city"
                        type="text"
                        placeholder={'Enter city'}
                        value={shipping_formik.values.city}
                        handleChange={shipping_formik.handleChange}
                        handleBlur={shipping_formik.handleBlur}
                      >
                        {shipping_formik.touched.city && shipping_formik.errors.city ? (
                          <div style={{ color: 'red', fontSize: '12px' }}>{shipping_formik.errors.city}</div>
                        ) : null}
                      </CustomInput>
                      <MapLocationFinder specificCountry={selectedCounryCode} getOfficeLocation={getOfficeLocation} label={'Address'} />

                      <div className="meta" />

                      {!handleCE_loader ? (
                        <div className="ce_btn_container">
                          <div className="btn_container">
                            <div className="btn_subC">
                              <Button bg_default={true} type={'button'} handleClick={() => setNexpage(false)}>
                                &#8592; Back
                              </Button>
                            </div>
                            <div className="btn_subC">
                              <Button bg_blue={true} type={'submit'} disabled={isDisAbled}>
                                Save Info
                              </Button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div style={{ margin: 'auto' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <CustomSpinner />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </form>
              </div>
            </Row>
        </div>
    
  );
};

export default CreateEmployee;
