import { useDispatch } from "react-redux";
import { decrease_ProductsAction, increase_ProductsAction } from "../../stateManagement/redux/actions/product/product.actions";
import "../../assets/css/components/shared/stepperInputStyles.css"

const StepperInput = ({name, value, initialState, sm, asset_id}) => {
  
  const dispatch = useDispatch();
  const findSlectedId = initialState?.find((item) => item.id === asset_id);

  const handleIncrement = () => {
      const payload = {
        asset_id: asset_id,
        quantity: findSlectedId.qty
      }
      dispatch(increase_ProductsAction(payload));
  }

  const handleDecrement = () => {
    const payload = {
      asset_id: asset_id,
      quantity: findSlectedId.qty
    }

    dispatch(decrease_ProductsAction(payload));
  }
  
  return (
    <div className="stepper_container">
        <button className={!sm ? "stepper-input__button" : "sm_stepper-input__button"} onClick={ handleDecrement}>-</button>
        <div className={!sm ? "stepper-input__content" : "sm_stepper-input__content"}>
            <input type="text" className={!sm ? "stepper-input__input" : "sm_stepper-input__input"} name={name} disabled value={value}  />
        </div>
        <button className={!sm ? "stepper-input__button" : "sm_stepper-input__button"} onClick={handleIncrement}>+</button>
    </div>
  )
}

export default StepperInput