import React from "react";
import Loadable from 'react-loadable';
import CustomSpinner from "../components/shared/CustomSpinner";
import { ProtectedLoginRoute } from "./ProtectLoginRoute";

// import Forbidden from "../pages/Forbidden";
// import ForgotPassword from "../rayda-auth/ForgotPassword";
// import InternalServerError from "../pages/InternalServerError";
// import NotFound from "../pages/NotFound";
// import ServiceUnavailable from "../pages/ServiceUnavailable";
// import Login from "../rayda-auth/Login";

const login = import('../rayda-auth/Login');
const setNewPassword = import('../rayda-auth/SetNewPassword');
const forgotPassword = import('../rayda-auth/ForgotPassword');
const internalServerError = import('../pages/InternalServerError');
const notFound = import('../pages/NotFound');
const serviceUnavailable = import('../pages/ServiceUnavailable');
const forbidden = import('../pages/Forbidden');

export const Login = Loadable({
  loader: () => login,
  loading: CustomSpinner
});
export const SetNewPassword = Loadable({
  loader: () => setNewPassword,
  loading: CustomSpinner
});

export const ForgotPassword = Loadable({
  loader: () => forgotPassword,
  loading: CustomSpinner
});

export const InternalServerError = Loadable({
  loader: () => internalServerError,
  loading: CustomSpinner
});

export const NotFound = Loadable({
  loader: () => notFound,
  loading: CustomSpinner
});

export const ServiceUnavailable = Loadable({
  loader: () => serviceUnavailable,
  loading: CustomSpinner
});

export const Forbidden = Loadable({
  loader: () => forbidden,
  loading: CustomSpinner
});

const authRoutes = [
  { path: "login", element: <ProtectedLoginRoute><Login /> </ProtectedLoginRoute>},
  { path: "forgot-password", element: <ForgotPassword /> },
  { path: "set-password/:code", element: <SetNewPassword /> },
  { path: "pages/error-404", element: <NotFound /> },
  { path: "pages/error-500", element: <InternalServerError /> },
  { path: "pages/error-503", element: <ServiceUnavailable /> },
  { path: "pages/error-505", element: <Forbidden /> }
];

export default authRoutes;