import React from 'react'
import { Spinner } from 'react-bootstrap'

const CustomSpinner = () => {
  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <Spinner animation="border" role="status" color='primary'></Spinner>
    </div>
  )
}

export default CustomSpinner