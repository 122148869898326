import { lazy_loader } from "../../assets/img"
import SliceText from "../../utils/SliceText"
import StepperInput from "./StepperInput"
import { AiOutlineDelete } from "react-icons/ai";

import "../../assets/css/components/shared/cartItemCardStyle.css"
import { remove_ProductAction } from "../../stateManagement/redux/actions/product/product.actions";
import { useDispatch } from "react-redux";
import toCurrency from "../../utils/toCurrency";

const CartItemCard = ({image, initialState, asset_id, quantity, asset_name, currency, price}) => {
    const dispatch = useDispatch();

    
    const handle_removeFromCart = () => {
        const payload = {
          asset_id: asset_id,
        }
        dispatch(remove_ProductAction(payload));
    }

    


  return (
    <div className="cartIem_container">
        <div className='image_area'>
            <div className="image_container">
                <img src={!image ? lazy_loader : image } alt="cart_item_img" />
            </div>
            
        </div>
                    
        <div className='header_content'>
            {
                asset_name && (
                    <h1>
                        <SliceText
                            number={15}
                            text={asset_name}
                        />
                    </h1>
                )
            }
            
            <div className="meta"/>

            <StepperInput
                sm
                name={"count"}
                value={quantity}
                initialState={initialState}
                asset_id={asset_id}
                currency={currency}
            />

        </div>

        <div className='header_action_area'>
            <AiOutlineDelete onClick={handle_removeFromCart}/>

            <div className="meta"/>

            <p>{currency ? toCurrency(price, currency) : price}</p>
        </div>
                    
    </div>
  )
}

export default CartItemCard