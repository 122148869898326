import Loadable from 'react-loadable';
import CustomSpinner from '../components/shared/CustomSpinner';

const dashboard = import('../private-sale/dashboard');
const viewCompanies = import('../private-sale/companies');
const viewAuctions = import('../private-sale/auctions');
const viewAuctionDetails = import('../private-sale/auctions/AuctionDetail');
const liveAuction = import('../private-sale/auctions/LiveAuctions');
const endedAuction = import('../private-sale/auctions/EndedAutions');
const winningBidDetails = import('../private-sale/auctions/WinningBidDetails');
const users = import('../private-sale/users/Users');
const viewPayments = import('../private-sale/payments/ViewPayments');
const pendingPayments = import('../private-sale/payments/PendingPayments');
const createMember = import('../private-sale/manage/CreateMember');
const members = import('../private-sale/manage/Members');
const editMember = import('../private-sale/manage/EditMember');

export const Dashboard = Loadable({
  loader: () => dashboard,
  loading: CustomSpinner
});

export const ViewCompanies = Loadable({
  loader: () => viewCompanies,
  loading: CustomSpinner
});

export const ViewAuctions = Loadable({
  loader: () => viewAuctions,
  loading: CustomSpinner
});

export const ViewAuctionDetails = Loadable({
  loader: () => viewAuctionDetails,
  loading: CustomSpinner
});

export const LiveAuction = Loadable({
  loader: () => liveAuction,
  loading: CustomSpinner
});

export const EndedAuction = Loadable({
  loader: () => endedAuction,
  loading: CustomSpinner
});

export const Users = Loadable({
  loader: () => users,
  loading: CustomSpinner
});

export const ViewPayments = Loadable({
  loader: () => viewPayments,
  loading: CustomSpinner
});

export const PendingPayments = Loadable({
  loader: () => pendingPayments,
  loading: CustomSpinner
});

export const CreateMember = Loadable({
  loader: () => createMember,
  loading: CustomSpinner
});

export const Members = Loadable({
  loader: () => members,
  loading: CustomSpinner
});

export const EditMember = Loadable({
  loader: () => editMember,
  loading: CustomSpinner
});

export const WinningBidDetails = Loadable({
  loader: () => winningBidDetails,
  loading: CustomSpinner
});

const privateSaleRoutes = [
  { path: "overview", element: <Dashboard /> },
  { path: "companies", element: <ViewCompanies /> },
  { path: "auction", element: <ViewAuctions /> },
  { path: "auction/:auctionId", element: <ViewAuctionDetails /> },
  { path: "auction/live-auction", element: <LiveAuction /> },
  { path: "auction/ended-auction", element: <EndedAuction /> },
  { path: "auction/winning-bids/:bidId", element: <WinningBidDetails /> },
  { path: "user/users", element: <Users /> },
  { path: "payments/view", element: <ViewPayments /> },
  { path: "payments/pending", element: <PendingPayments /> },
  { path: "manage/create-member", element: <CreateMember /> },
  { path: "manage/members", element: <Members /> },
  { path: "manage/members/:admin_id", element: <EditMember /> },
]

export default privateSaleRoutes;