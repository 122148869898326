import { Navigate } from 'react-router-dom';
import { getToken } from '../services/helper';

export const CustomPortalProtectedRoute = ({ children }) => {
  if (!getToken()) {
    return <Navigate to="/login" />;
  }

  return children;
};
