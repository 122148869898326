import React, { useState } from "react";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'sweetalert2/src/sweetalert2.scss'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Main from './layouts/RaydaRemote';
import NotFound from "./pages/NotFound";
import Loadable from 'react-loadable';

// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";
import raydaRemoteRoutes from "./routes/rayda-remote";
import authRoutes from "./routes/auth";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CustomPortalProtectedRoute } from "./routes/ProtectedPortalRoutes";
import CustomSpinner from "./components/shared/CustomSpinner";
import privateSaleRoutes from "./routes/private-sale";
import AppContext from "./stateManagement/contextApi";
import CartModal from "./components/constants/Cart";

const raydaRemote = import('./layouts/RaydaRemote');
const privateSale = import('./layouts/private-sale');

export const RaydaRemote = Loadable({
  loader: () => raydaRemote,
  loading: CustomSpinner
});

export const PrivateSale = Loadable({
  loader: () => privateSale,
  loading: CustomSpinner
});

// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  const [openCart, setOpenCart] = useState(false);
  return (
    <React.Fragment>
      <ToastContainer />
      <AppContext.Provider
        value={{
          openCart,
          setOpenCart,
        }}>
        <BrowserRouter>
          <Routes>
            <Route path="/rayda-remote" element={<CustomPortalProtectedRoute><RaydaRemote /></CustomPortalProtectedRoute>}>
              {raydaRemoteRoutes.map((route, index) => {
                return (
                  <Route
                    path={route.path}
                    element={route.element}
                    key={index}
                  />
                )
              })}

              <Route exact path="" element={<Navigate replace to="/rayda-remote/overview" />} />
            </Route>

            <Route path="/private-sale" element={<CustomPortalProtectedRoute><PrivateSale /></CustomPortalProtectedRoute>}>
              {privateSaleRoutes.map((route, index) => {
                return (
                  <Route
                    path={route.path}
                    element={route.element}
                    key={index}
                  />
                )
              })}

              <Route exact path="" element={<Navigate replace to="/private-sale/overview" />} />
            </Route>

            {authRoutes.map((route, index) => {
              return (
                <Route
                  path={route.path}
                  element={route.element}
                  key={index}
                />
              )
            })}
            <Route exact path="/" element={<Navigate replace to="/login" />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <CartModal/>
        </BrowserRouter>
      </AppContext.Provider>
      
    </React.Fragment>
    
  );
}