import { requestAddOrder, requestAllAsset, requestAllClients, requestAllCompanies, requestAllEmployee, requestAllOrders, requestCancelOrder, requestCompanyAssets, requestCompanyOrder, requestCompanyUsers, requestCreateCompanyDetails, requestCreateEmployee, requestDashboardData, requestShippingOptions, requestSingleClients, requestSingleCompany, requestSingleEmployee, requestSingleOrder, requestUpdateOrderStatus } from "../requests/raydaRemote.request";

export const dashboardAction = async ({ queryKey }) => {
    try {
      const [_] = queryKey;
      const data = await requestDashboardData();
      return data.data;
    } catch (error) {
      throw error.response.data;
    }
};

export const allCompaniesAction = async ({ queryKey }) => {
    try {
      const [_] = queryKey;
      const data = await requestAllCompanies();
      return data.data;
    } catch (error) {
      throw error.response.data;
    }
};

export const createCompanyDetailsAction = async ({ formData, userId }) => {
  try {
    const data = await requestCreateCompanyDetails(formData, userId);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const singleCompanyAction = async ({ queryKey }) => {
    try {
      const [_, company_id] = queryKey;
      const data = await requestSingleCompany(company_id);

      return data.data;
    } catch (error) {
      throw error.response.data;
    }
};

export const allEmployeeAction = async ({ queryKey }) => {
    try {
      const [_,company_id] = queryKey;
      const data = await requestAllEmployee(company_id);

      return data.data;
    } catch (error) {
      throw error.response.data;
    }
};

export const companyOrderAction = async ({ queryKey }) => {
    try {
      const [_,company_id] = queryKey;
      const data = await requestCompanyOrder(company_id);

      return data.data;
    } catch (error) {
      throw error.response.data;
    }
};

export const companyUsersAction = async ({ queryKey }) => {
    try {
      const [_,company_id] = queryKey;
      const data = await requestCompanyUsers(company_id);

      return data.data;
    } catch (error) {
      throw error.response.data;
    }
};

export const companyAssetsAction = async ({ queryKey }) => {
    try {
      const [_,company_id] = queryKey;
      const data = await requestCompanyAssets(company_id);

      return data.data;
    } catch (error) {
      throw error.response.data;
    }
};

export const singleEmployeeAction = async ({ queryKey }) => {
    try {
        const [_,employee_id] = queryKey;
        const data = await requestSingleEmployee(employee_id);

        return data.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const allOrdersAction = async ({ queryKey }) => {
    try {
      const [_] = queryKey;
      const data = await requestAllOrders();

      return data.data;
    } catch (error) {
      throw error.response.data;
    }
  };

export const singleOrdersAction = async ({ queryKey }) => {
    try {
        const [_,order_id] = queryKey;
        const data = await requestSingleOrder(order_id);

        return data.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const updateOrderStatusAction = async ({ order_id, lastNode, formData }) => {
  try {
    const data = await requestUpdateOrderStatus(order_id, lastNode, formData);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const allClientsAction = async ({ queryKey }) => {
  try {
    const [_] = queryKey;
    const data = await requestAllClients();

    return data.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const singleClientAction = async ({ queryKey }) => {
  try {
      const [_,client_id] = queryKey;
      const data = await requestSingleClients(client_id);

      return data.data;
  } catch (error) {
      throw error.response.data;
  }
};

export const createEmployeeAction = async ({ company_id, formData }) => {
  try {
    const data = await requestCreateEmployee(company_id, formData);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};


export const allAssetAction = async ({ queryKey }) => {
  try {
    const [_] = queryKey;
    const data = await requestAllAsset();

    return data.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const shippingOptionAction = async ({ queryKey }) => {
  try {
    const [_] = queryKey;
    const data = await requestShippingOptions();
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const addOrderAction = async ({ formData }) => {
  try {
    const data = await requestAddOrder(formData);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const cancelOrderAction = async ({ order_id }) => {
  try {
    const data = await requestCancelOrder(order_id);

    return data;
  } catch (error) {
    throw error.response.data;
  }
};