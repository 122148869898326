export const total = (data) => {
  let count = 0;

  data.map((item) => {
    if (item.price) {
      return (count += item.price);
    }
    return null;
  });

  return count;
};
