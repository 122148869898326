import { requestAllAdmins, requestCreatAdmin, requestEditAdmin, requestLogin, requestLogout, requestSetPassword, requestSingleAdmin } from "../requests/auth.request";

export const createAdminAction = async ({ formData }) => {
  try {
    const data = await requestCreatAdmin(formData);

    return data.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const editAdminAction = async ({ admin_id, formData }) => {
  try {
    const data = await requestEditAdmin(admin_id, formData);

    return data.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const loginAction = async ({ formData }) => {
  try {
    const data = await requestLogin(formData);

    return data.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const setPasswordAction = async ({ formData }) => {
  try {
    const data = await requestSetPassword(formData);

    return data.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const logoutAction = async () => {
  try {
    const data = await requestLogout();
    return data.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const allAdminsAction = async ({ queryKey }) => {
  try {
    const [_] = queryKey;
    const data = await requestAllAdmins();
    return data.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const singleAdminAction = async ({ queryKey }) => {
  try {
    const [_, admin_id] = queryKey;
    const data = await requestSingleAdmin(admin_id);
    return data.data;
  } catch (error) {
    throw error.response.data;
  }
};
