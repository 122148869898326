import { useContext, useEffect, useState } from "react";
import AppContext from "../../stateManagement/contextApi";
import { useDispatch, useSelector } from "react-redux";
import CustomPortalModal from "../shared/CustomPortalModal";
import CartItemCard from "../shared/CartItemCard";
import EmptyInstance from "../shared/EmptyInstance";
import { total } from "../../utils/total";

import "../../assets/css/components/cartModalStyle.css"
import Button from "../shared/Button";
import { remove_All_ProductAction } from "../../stateManagement/redux/actions/product/product.actions";
import toCurrency from "../../utils/toCurrency";


const CartModal = () => {
    const dispatch = useDispatch();

    const { openCart, setOpenCart } = useContext(AppContext);

    const [sum, setSum] = useState();

    const productToCart = useSelector(({productToCart}) => productToCart);

    const handleClearCart = async () => {
        dispatch(remove_All_ProductAction());
    }

    useEffect(() => {
        let prices = [];
        productToCart?.map(({ qty, price }) => prices.push(price * qty));
        setSum(prices.reduce((a, b) => a + b, 0))

    }, [productToCart]);

    const handleGotoNextpage = () => {
        setOpenCart((prev) => !prev)

    };

    const totalPrice = !productToCart.length < 1 ? total(productToCart) : "";


    return (
        <CustomPortalModal open={openCart} setOpen={setOpenCart} hasHeader_image headerText_title={"Your Cart"} headerText_subtitle="Manage IT inventory with our shopping cart">
            <article className="cartModal_container">
                <section className="order_items_area">
                    {/* cart && !JSON.parse(cart?.assets)?.length < 1 */}
                    {
                      productToCart ? productToCart?.map(({ id, qty, asset_name, price, currency, image}, index) => (
                            <CartItemCard key={index} asset_id={id} asset_name={asset_name} price={price * qty} currency={currency} quantity={qty}  image={image} initialState={productToCart} />
                        )) : (
                            <EmptyInstance
                                text_only
                                title={"Your cart is empty!"}
                                sub_title={"Add your favorite items to your cart."}
                            />
                        )
                    }

                </section>

                {
                    totalPrice && (
                        <section className="cost_area">

                            <div className="list_items">
                                <h3 className="total">Total</h3>
                                <p>{ toCurrency(sum, "NGN")}</p>
                            </div>

                        </section>
                    )
                }
                
                <section className="description_area">
                    <h3>Asset Protection</h3>
                    <p>All assets purchased through the website will have insurance coverage in case of any damage or loss during transit, after delivery and duration of use.</p>
                </section>

                <section className="order_items_footer">
                    <Button bg_default disabled={productToCart.length < 1}  handleClick={handleClearCart}>
                        Clear Cart
                    </Button>
                    
                    {'\u00A0'}
                    <Button bg_blue={true} handleClick={handleGotoNextpage}>
                        Continue Shopping
                    </Button>
                </section>
            </article>
        </CustomPortalModal>
    )
}

export default CartModal;