import React from "react";
import Loadable from 'react-loadable';
import CustomSpinner from "../components/shared/CustomSpinner";
import CreateEmployee from "../rayda-remote/CreateEmployee";

const dashboard = import('../rayda-remote');
const tables = import('../docs/Tables');
const company = import('../rayda-remote/companies/Company');
const createCompany = import('../rayda-remote/companies/CreateCompany');
const companyList = import('../rayda-remote/companies/CompanyList');
const orderList = import('../rayda-remote/orders/OrderList');
const employeesDetails = import('../rayda-remote/companies/EmployeesDetails');
// const createEmployees = import('../rayda-remote/CreateEmployee');
const order = import('../rayda-remote/orders/Order');
const addOrder = import('../rayda-remote/orders/AddOrder');
const createMember = import('../rayda-remote/manage/CreateMember');
const members = import('../rayda-remote/manage/Members');
const editMember = import('../rayda-remote/manage/EditMember');
const clientList = import('../rayda-remote/clients/ClientList');
const client = import('../rayda-remote/clients/Client');

export const Dashboard = Loadable({
  loader: () => dashboard,
  loading: CustomSpinner
});

export const Tables = Loadable({
  loader: () => tables,
  loading: CustomSpinner
});

export const Company = Loadable({
  loader: () => company,
  loading: CustomSpinner
});

export const CreateCompany = Loadable({
  loader: () => createCompany,
  loading: CustomSpinner
});

export const CompanyList = Loadable({
  loader: () => companyList,
  loading: CustomSpinner
});

export const OrderList = Loadable({
  loader: () => orderList,
  loading: CustomSpinner
});

export const EmployeesDetails = Loadable({
  loader: () => employeesDetails,
  loading: CustomSpinner
});

// export const CreateEmployees = Loadable({
//   loader: () => createEmployees,
//   loading: CustomSpinner
// });

export const Order = Loadable({
  loader: () => order,
  loading: CustomSpinner
});

export const AddOrder = Loadable({
  loader: () => addOrder,
  loading: CustomSpinner
});

export const CreateMember = Loadable({
  loader: () => createMember,
  loading: CustomSpinner
});

export const Members = Loadable({
  loader: () => members,
  loading: CustomSpinner
});

export const EditMember = Loadable({
  loader: () => editMember,
  loading: CustomSpinner
});

export const ClientList = Loadable({
  loader: () => clientList,
  loading: CustomSpinner
});

export const Client = Loadable({
  loader: () => client,
  loading: CustomSpinner
});



const raydaRemoteRoutes = [
  { path: "overview", element: <Dashboard /> },
  { path: "test", element: <Tables /> },
  { path: "companies/views", element: <CompanyList /> },
  { path: "companies/create", element: <CreateCompany /> },
  { path: "company/:company_id", element: <Company /> },
  { path: "company/employee/:employee_id", element: <EmployeesDetails /> },
  { path: "company/employee/create", element: <CreateEmployee /> },
  { path: "orders/views", element: <OrderList /> },
  { path: "orders/add-orders", element: <AddOrder /> },
  { path: "order/:order_id", element: <Order /> },
  { path: "manage/create-member", element: <CreateMember /> },
  { path: "manage/members", element: <Members /> },
  { path: "manage/members/:admin_id", element: <EditMember /> },
  { path: "clients/views", element: <ClientList /> },
  { path: "clients/:client_id", element: <Client /> },
]

export default raydaRemoteRoutes;