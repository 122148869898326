import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './assets/css/reset.css'
import { Provider } from 'react-redux';
import store from './store';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { toast } from 'react-toastify';

const mutationCache = new MutationCache({
  onError: (error, _variables, _context, mutation) => {
    // If this mutation has an onError defined, skip this
    if (mutation.options.onError) return;
    // any error handling code...
    toast.error(`Something went wrong: ${error.message}`);
  }
});

const queryCache = new QueryCache({
  onError: (error, _variables, _context, query) => {
    // If this query has an onError defined, skip this
    // if (query.options.onError) return;
    // any error handling code...
    toast.error(`Something went wrong: ${error.message}`);
  }
});

const queryClient = new QueryClient({ mutationCache, queryCache });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>
    <div className="main-backdrop"></div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
